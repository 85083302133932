export const environment = {
    production: true,
    envName: "fromTFS",
    clientID: "f04e42d5-3064-4c41-ab52-e47a954beea0",
    urlCloudApi: "https://testaccount.ansys.com/",
    authority: "https://login.microsoftonline.com/tfp/a365dev.onmicrosoft.com/B2C_1_Account_sign_in_test",
    adminGroupId: "375184be-7f1e-4dc2-88a7-5bd245c3afd6",
    oidc: "https://testaccountregistration.ansys.com",
    oidcClientId: "portal",
    appInsights: { instrumentationKey: "54d6f4e8-d1a6-46b5-a0a0-8776e30c4332" },
};
